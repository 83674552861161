<template>
  <v-container>
    <v-sheet class="pa-2 mt-3">
      <v-data-table
        :headers="headers[$vuetify.breakpoint.xsOnly]"
        :items="items"
        :items-per-page="7"
        item-key="code"
        sort-by="name"
        mobile-breakpoint="0"
        class="table-cursor"
        :search="search"
        loading-text="Cargando ..."
        @click:row="handleClick"
      >
        <template #top>
          <v-toolbar flat>
            <v-toolbar-title>Proveedores</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              hide-details
              append-icon="mdi-magnify"
              single-line
              dense
              outlined
              placeholder="Buscar proveedor"
            />
            <v-btn class="mx-2" fab dark small color="success" @click="newForm()">
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.picture`]="{ item }" class="pa-0 ma-0">
          <v-avatar v-if="item.picture">
            <v-img :src="getImagePhoto(item.account, item.code, item.picture)"></v-img>
          </v-avatar>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <b>{{ item.name }}</b>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-icon v-if="item.status === '1'">mdi-checkbox-outline</v-icon>
          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
        </template>
      </v-data-table>

      <PartyForm
        table="suppliers"
        :item="item"
        :dialog="dialog"
        :newItem="newItem"
        @close="dialog = false"
        @refresh="refresh()"
      />
    </v-sheet>
  </v-container>
</template>

<script>
import { webserver } from "../../services/webserver.js";
import createItem from "../../utils/createParty.js";
import PartyForm from "../../components/PartyForm.vue";
export default {
  components: { PartyForm },
  mounted() {
    this.get_items();
  },
  data() {
    return {
      dialog: false,
      newItem: false,
      search: "",
      loading_status: false,
      errors: [],
      items: [],
      item: createItem(),
      headers: {
        false: [
          {
            text: "",
            align: "start",
            sortable: true,
            value: "picture",
            dataType: "text",
            width: 40,
          },
          {
            text: "Nombre",
            align: "start",
            sortable: true,
            value: "name",
            dataType: "text",
          },
          {
            text: "Dirección",
            align: "start",
            sortable: true,
            value: "address",
            dataType: "text",
          },
          {
            text: "Ciudad",
            align: "start",
            sortable: true,
            value: "city",
            dataType: "text",
          },
          {
            text: "Email",
            align: "start",
            sortable: true,
            value: "email",
            dataType: "text",
          },
          {
            text: "Tel.",
            align: "end",
            value: "mobile",
            dataType: "text",
            width: 150,
          },
        ],
        true: [
          {
            text: "",
            align: "start",
            sortable: true,
            value: "picture",
            dataType: "text",
            width: 40,
          },
          {
            text: "Nombre",
            align: "start",
            sortable: true,
            value: "name",
            dataType: "text",
          },
          {
            text: "Tel.",
            align: "end",
            value: "mobile",
            dataType: "text",
            width: 150,
          },
        ],
      },
    };
  },
  methods: {
    openURL(e) {
      window.open(e, "", "width=400,height=800");
    },
    handleClick(data) {
      this.item = { ...data };
      this.newItem = false;
      this.dialog = true;
    },
    updateLocation(e) {
      this.store.location = e;
    },
    get_items() {
      var qry = {
        account: this.$store.state.profile.account,
        table: "suppliers",
        filters: [],
      };
      this.$store.dispatch("setloading", true);
      webserver("get_table", qry, (data) => {
        this.$store.dispatch("setloading", false);
        this.items = data;
        if (data.length > 0) {
          this.items = data;
        } else {
          this.newForm();
        }
      });
    },
    refresh() {
      this.dialog = false;
      this.get_items();
    },
    getImagePhoto: function (account, code, src) {
      var currentdate = new Date();
      var url =
        "https://" +
        this.$store.getters.getHost +
        "/" +
        account +
        "/suppliers/" +
        src +
        "?" +
        currentdate;
      return url;
    },
    newForm() {
      this.item = createItem();
      this.item.account = this.$store.state.profile.account;
      this.item.store = this.$store.state.company.code;
      this.item.location = "";
      this.valid = false;
      this.newItem = true;
      this.dialog = true;
    },
    call_contact(data) {
      window.location.href = "tel://" + data;
    },
  },
  computed: {
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
.float {
  position: fixed;
  width: 40px;
  height: 40px;
  top: 64px;
  right: 20px;
  background-color: #0c9;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}

.text-start {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
</style>
